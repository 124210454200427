import {
  change_password,
  update_user,
  getInfo,
  entry_list,
  MatchList,
  upload_code,
} from "../../api/ThisInfo.js";
import axios from "axios";
import { publicbaseURLs } from "@/utils/request";
import { getToken } from "@/utils/auth";
export default {
  name: "PersonalInfos",
  data() {
    return {
        UrlApi: publicbaseURLs() + "/image_upload_user", //上传学生证图片地址
        UserInfoAll: null,
        EntryListAll: [], //个人比赛列表
        dialogVisible: false, //修改个人信息弹窗状态
        update_info: {}, //修改个人信息表单
        // 修改个人信息表单验证
        rules: {
            username: [
                { required: true, message: "请输入手机号", trigger: "blur" },
                // { pattern: /^1[34578]d{9}$/, message: "手机号格式错误" },
            ],
            password: [
                { required: true, message: "请输入密码", trigger: "blur" },
            ],
            specialty: [
                { required: true, message: "请输入专业", trigger: "blur" },
            ],
            grade: [{ required: true, message: "请输入年级", trigger: "blur" }],
            education_background: [
                { required: true, message: "请输入学历", trigger: "blur" },
            ],
            realname: [
                { required: true, message: "请输入真实姓名", trigger: "blur" },
            ],
            nickname: [
                { required: true, message: "请输入昵称", trigger: "blur" },
            ],
            student_num: [
                { required: true, message: "请输入真实学号", trigger: "blur" },
            ],
            school_name: [
                { required: true, message: "请输入真实学校", trigger: "blur" },
            ],
            email: [
                { required: true, message: "请输入真实邮箱", trigger: "blur" },
                {
                    pattern:
                        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                    message: "邮箱格式错误",
                },
            ],
            faculty_adviser: [
                { required: true, message: "请输入指导老师", trigger: "blur" },
            ],
            verification_code: [
                {
                    required: true,
                    message: "请输入手机验证码",
                    trigger: "blur",
                },
            ],
        },
        PasswordDialogVisible: false, //修改个人密码
        Form: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        }, //修改密码表单
        rulesForm: {
            //修改密码验证
            old_password: [
                { required: true, message: "请输入旧密码", trigger: "blur" },
            ],
            new_password: [
                { required: true, message: "请输入新密码", trigger: "blur" },
            ],
            confirm_password: [
                {
                    required: true,
                    message: "请验证新密码是否一致",
                    trigger: "blur",
                },
            ],
        },
        FileList: [], //学生证附件
        gradeList: [],
        MathDataList: [], //比赛列表
        code_type_List: [
            "Python",
            "Java",
            "C",
            "C++",
            "SAS",
            "R",
            "Matlab",
            "JavaScript",
            "C#",
            "Go",
        ], //代码类型
        educationBackgroundList: [
            "高中及以下",
            "大专",
            "本科",
            "硕士研究生",
            "博士研究生",
        ],
        code_type: "", //代码类型
        ChangeMathId: null, //选中比赛状态
        FileSourceCodeState: false, //上传源码弹窗列表
        FileSource: [], //源码文件列表
    };
  },
  mounted() {
    this.getInfodata();
    this.getEntryList();
    this.getNf();
    this.GetMatchSearch();
  },
  watch: {
    $route(route) {
      if (route.path == "/login") {
        this.$destroy(this.$options.name);
      }
    },
  },
  methods: {
    // 源文件下载
    SingleitemDow(URL) {
      window.location.href = URL;
      return;
    },
    // 获取比赛列表
    GetMatchSearch() {
      MatchList({
        pageNum: "1",
        pageSize: "100",
      }).then((res) => {
        this.MathDataList = res.data.list;
      });
    },
    getNf() {
      var nfOptionsArray = new Array();
      var years = new Date().getFullYear();
      for (let i = years - 9; i <= years; i++) {
        var anOption = {};
        anOption.dictValue = i + "";
        anOption.dictLabel = i + "";
        nfOptionsArray.push(anOption);
      }
      this.gradeList = nfOptionsArray;
    },
    getEntryList() {
      entry_list().then((res) => {
        if (res.code == 200) {
          this.EntryListAll = res.data;
        }
      });
    },
    // 获取用户信息
    getInfodata() {
      getInfo().then((res) => {
        this.UserInfoAll = JSON.parse(JSON.stringify(res.data));
        this.$forceUpdate();
      });
    },
    // 查看所有个人比赛分数
    ViewACompetitionScorell(item) {
      this.$router.push({
        path: "/SingleItem",
        query: {
          id: item.challenge_id,
          state: 2,
          matchstate: item.challenge_status,
        },
      });
    },
    // 登出操作
    LogOut() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("FedLogOut", this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || "/login",
              });
            })
            .catch(() => {
              this.loading = false;
            });
          this.$message({
            type: "success",
            message: "退出登录!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    // 修改个人信息事件
    ModifyInfo() {
      this.update_info = JSON.parse(JSON.stringify(this.UserInfoAll));
      this.dialogVisible = true;
    },
    // 取消修改个人信息
    cancelModifyInfo() {
      this.update_info = {};
      this.dialogVisible = false;
    },
    // 确定修改个人信息
    ConfirmModifyInfo() {
      delete this.update_info.username;
      this.$refs["update_info"].validate((valid) => {
        if (valid) {
          update_user({ update_info: this.update_info }).then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false;
              this.$message({
                message: "个人信息修改成功",
                type: "success",
              });
              this.getInfodata();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改密码
    ModifyPassword() {
      this.PasswordDialogVisible = true;
    },
    // 取消修改密码
    cancelModifyPassword() {
      this.Form = {};
      this.PasswordDialogVisible = false;
    },
    // 确认修改密码
    ConfirmModifyPassword() {
      this.$refs["Form"].validate((valid) => {
        if (valid) {
          change_password(this.Form).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "密码修改成功",
                type: "success",
              });
              this.PasswordDialogVisible = false;
              this.$store
                .dispatch("FedLogOut", {})
                .then(() => {
                  this.$router.push({
                    path: this.redirect || "/login",
                  });
                })
                .catch(() => {});
            }
          });
        } else {
          return false;
        }
      });
    },
    Preview() {},
    Remove(file, fileList) {
      this.FileList = fileList;
    },
    success() {
      this.$store.commit("app/CHANGE_LOADING", true);
      if (this.UserInfoAll.student_card_path) {
        this.UrlApi = publicbaseURLs() + "/update_user_image";
      } else {
        this.UrlApi = publicbaseURLs() + "/image_upload_user";
      }
      var data = new FormData();
      data.append("file", this.FileList);
      data.append("token", getToken());
      axios({
        url: this.UrlApi,
        method: "post",
        data: data,
        headers: {
          Authorization: getToken(),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "上传成功",
            });
            this.$store.commit("app/CHANGE_LOADING", false);
            this.FileList = [];
            this.getInfodata();
            this.$router.go(0);
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
            this.$store.commit("app/CHANGE_LOADING", false);
            this.FileList = [];
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "上传失败",
          });
          this.$store.commit("app/CHANGE_LOADING", false);
          this.FileList = [];
        });
    },
    Change(res) {
      this.FileList = res.raw;
    },
    // 上传源码按钮
    UploadFileSourceCode() {
      this.FileSourceCodeState = true;
      this.ChangeMathId = null;
    },
    // 取消源码上传
    cancelUploadSourceCode() {
      this.FileSourceCodeState = false;
      this.ChangeMathId = null;
      this.FileSource = [];
    },
    // 确定上传源码
    ConfirmUploadSourceCode() {
      if (!this.ChangeMathId) {
        this.$message({
          type: "error",
          message: "请选择需要上传的比赛",
        });
        return;
      }
      if (!this.code_type) {
        this.$message({
          type: "error",
          message: "请选择代码类型",
        });
        return;
      }
      if (!this.FileSource.length > 0) {
        this.$message({
          type: "error",
          message: "请选择需要上传的比赛源码文件",
        });
        return;
      }
      var data = new FormData();
      data.append("file", this.FileSource[0].raw);
      upload_code(data,this.ChangeMathId,this.code_type)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.cancelUploadSourceCode();
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
            this.cancelUploadSourceCode();
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.msg,
          });
        });
    },
    FileSourceChange(res) {
      this.FileSource = [res];
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
  },
};
